<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-body p-0">
          <div class="user-tabing p-3">
            <ul class="nav nav-pills d-flex align-items-center justify-content-center profile-forum-items text-center">
              <li class="col-md-4 p-0 nav-item">
                <a class="nav-link active" data-toggle="pill" href="#personal-information">
                  Personal Information
                </a>
              </li>
              <li class="col-md-4 p-0 nav-item">
                <a class="nav-link" data-toggle="pill" href="#about">
                  About
                </a>
              </li>
              <li class="col-md-4 p-0">
                <a class="nav-link" data-toggle="pill" href="#chang-pwd">
                  Change Password
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="iq-edit-list-data">
        <div class="tab-content">
          <div class="tab-pane fade active show" id="personal-information" role="tabpanel">
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Personal Information</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <form>
                  <div class="form-group row align-items-center">
                    <div class="col-md-12">
                      <div class="profile-img-edit">
                        <img class="profile-pic" :src="authUser.avatar" alt="profile-pic">
                        <div class="p-image">
                          <i class="ri-pencil-line upload-button"></i>
                          <input class="file-upload" type="file" accept="image/*"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" row align-items-center">
                    <div class="form-group col-sm-6">
                      <label for="fname">First Name:</label>
                      <input type="text" class="form-control" id="fname" :value="authUser.firstName">
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="lname">Last Name:</label>
                      <input type="text" class="form-control" id="lname" :value="authUser.lastName">
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="uname">User Name:</label>
                      <input type="text" class="form-control" id="uname" :value="authUser.username">
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary mr-2">Submit</button>
                  <button type="reset" class="btn iq-bg-danger">Cancel</button>
                </form>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="about" role="tabpanel">
            <iq-card>
              <template v-slot:body>
                <ProfileAbout/>
              </template>
            </iq-card>
          </div>
          <div class="tab-pane fade" id="chang-pwd" role="tabpanel">
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Change Password</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <form>
                  <div class="form-group">
                    <label for="cpass">Current Password:</label>
                    <a href="javascripe:void();" class="float-right">Forgot Password</a>
                    <input type="Password" class="form-control" id="cpass" value="">
                  </div>
                  <div class="form-group">
                    <label for="npass">New Password:</label>
                    <input type="Password" class="form-control" id="npass" value="">
                  </div>
                  <div class="form-group">
                    <label for="vpass">Verify Password:</label>
                    <input type="Password" class="form-control" id="vpass" value="">
                  </div>
                  <button type="submit" class="btn btn-primary mr-2">Submit</button>
                  <button type="reset" class="btn iq-bg-danger">Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { socialvue } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import * as authGetters from '@/store/modules/auth/types/getters'
import ProfileAbout from '@/components/homeiz/profiles/ProfileAbout'

export default {
  name: 'ProfileEdit',
  components: { ProfileAbout },
  mounted () {
    socialvue.index()
  },
  data () {
    return {
      genderOptions: [
        { value: 1, text: 'Male' },
        { value: 2, text: 'Female' }
      ],
      user: {
        fname: '',
        lname: '',
        name: '',
        username: '',
        email: '',
        password: '',
        address1: '',
        address2: '',
        company_name: '',
        mobile_no: '',
        country: '',
        state: '',
        city: '',
        pincode: '',
        role: '',
        gender: null,
        dob: '',
        url: ''
      },
      currentPassword: '',
      countries: [
        { value: 'Canada', text: 'Canada' },
        { value: 'Niada', text: 'Niada' },
        { value: 'USA', text: 'USA' },
        { value: 'India', text: 'India' },
        { value: 'Africa', text: 'Africa' }
      ],
      states: [
        { value: 'California', text: 'California' },
        { value: 'Florida', text: 'Florida' },
        { value: 'Georgia', text: 'Georgia' },
        { value: 'Connecticut', text: 'Connecticut' },
        { value: 'Louisiana', text: 'Louisiana' }
      ]
    }
  },
  methods: {
    onSubmit () {
      this.user.name = this.user.fname + ' ' + this.user.lname
      this.$router.replace('/user/user-list')
    },
    previewImage: function (event) {
      const input = event.target

      if (input.files && input.files[0]) {
        const reader = new FileReader()

        reader.onload = (e) => {
          this.user.profile_image = e.target.result
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    changePassword: function () {
    }
  },
  computed: {
    ...mapGetters('auth', {
      authUser: authGetters.GET_AUTH_USER
    })
  }
}
</script>
